import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import { mobileBreakpoint } from "../../Services/config";
// State
import { useDispatch, useSelector } from "react-redux";
import {
  setShowSideDrawer,
  setOpenCustomerToDoList,
} from "../../State/slices/session-slice";
// Components
import ProfileIcon from "../ProfileIcon";
import BurgerMenuIcon from "../BurgerMenuIcon";
import { useViewport } from "../ViewportProvider";
import TaskList from "../../Components/TaskList";
// Icons
import IconButton from "@mui/material/IconButton";
import { BellIcon, ToDoListIcon } from "../../Styles/Icons/DesignSystem";

const Header = ({ navigate }) => {
  // Get the width of the users screen for responsive js
  const { width } = useViewport();
  const onMobile = width <= mobileBreakpoint;

  // Fetch the user from redux state
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const session = useSelector((state) => state.session);
  const openCustomerToDoList = session?.openCustomerToDoList;
  const unreadNotifications = [];
  const TASKS = [
    {
      id: 1,
      text: "Intro to Lilli kit video",
      completed: true,
      navigateTo: "",
    },
    {
      id: 5,
      text: "Ensuring success post-install",
      completed: false,
      navigateTo: "",
    },
  ];

  return (
    <header className={styles.header}>
      <TaskList
        taskList={TASKS}
        title={"Get familiar with Lilli"}
        subheading={"Let's get you set up"}
        isOpen={openCustomerToDoList}
        onClose={() => dispatch(setOpenCustomerToDoList(false))}
      />
      <div className={styles.header_left}>
        {onMobile && (
          <div className={styles.header_left_logo}>
            <BurgerMenuIcon onClick={() => dispatch(setShowSideDrawer(true))} />
          </div>
        )}
      </div>
      <div className={styles.header_right}>
        {!!user && (
          <>
            <div className={styles.header_to_do_list}>
              <IconButton
                onClick={() => dispatch(setOpenCustomerToDoList(true))}
              >
                <ToDoListIcon />
              </IconButton>
            </div>
            <div className={styles.header_element}>
              <IconButton
                onClick={() => navigate("/notifications")}
                size="large"
              >
                <BellIcon width={22} colour={theme.neutral4} />
              </IconButton>
              {unreadNotifications.length > 0 && (
                <div className={styles.header_element_dot} />
              )}
            </div>
            <div className={styles.header_profile}>
              <ProfileIcon user={user} onClick={() => navigate("/profile")} />
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
