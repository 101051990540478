import React, { useState } from "react";
import styles from "./styles.module.scss";

const TaskList = ({ taskList, title, subheading, isOpen, onClose }) => {
  const [tasks, setTasks] = useState(taskList);

  const handleTaskToggle = (id) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === id ? { ...task, completed: !task.completed } : task
      )
    );
  };

  const completedTasks = tasks.filter((task) => task.completed).length;

  return (
    isOpen && (
      <div className={styles.task_list_container}>
        <div className={styles.header}>
          <div className={styles.heading}>{title.toUpperCase()}</div>
          <button className={styles.close_button} onClick={onClose}>
            &times;
          </button>
        </div>
        <div>
          <h2 className={styles.subheading}>{subheading}</h2>
          <div className={styles.progress_bar}>
            {tasks.map((_, index) => (
              <span
                key={index}
                className={`${styles.progress_segment} ${
                  index < completedTasks ? styles.completed : ""
                }`}
              ></span>
            ))}
          </div>
          <ul className={styles.task_list}>
            {tasks.map((task) => (
              <li
                key={task.id}
                className={`${styles.task_item} ${
                  task.completed ? styles.completed_task : ""
                }`}
                onClick={() => handleTaskToggle(task.id)}
              >
                <span
                  className={`${styles.icon} ${
                    task.completed ? styles.green_check : ""
                  }`}
                >
                  {task.completed && "✔"}
                </span>
                <span
                  className={`${styles.text} ${
                    task.completed ? styles.completed_text : ""
                  }`}
                >
                  {task.text}
                </span>
              </li>
            ))}
          </ul>
        </div>
        <button className={styles.widget_is_visible} onClick={onClose}>
          {"Hide to do list"}
        </button>
      </div>
    )
  );
};

export default TaskList;
